import React from "react";

import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import styled from "styled-components";

import Layout from "../components/Layout";
import Container from "../components/Container";
import Testimonial from "../components/Testimonial";

import Carousel from "re-carousel";

const introWidth = 1122;

const CarouselImg = styled(Img)`
  width: 100%;
`;

export default () => {
  const data = useStaticQuery(query);

  /// ... Delete this ... ////

  return (
    <Layout>
      <CarouselContainer width={1200}>
        <Carousel auto loop interval={4000}>
          <CarouselImg
            fluid={data.banner0.childImageSharp.fluid}
            alt="School logo"
          />
          <CarouselImg
            fluid={data.banner5.childImageSharp.fluid}
            alt="banner image 5"
          />
          {/*<CarouselImg
            fluid={data.banner1.childImageSharp.fluid}
            alt="banner image 1"
          />*/}
          <CarouselImg
            fluid={data.banner2.childImageSharp.fluid}
            alt="banner image 2"
          />
          <CarouselImg
            fluid={data.banner3.childImageSharp.fluid}
            alt="banner image 3"
          />
          <CarouselImg
            fluid={data.banner4.childImageSharp.fluid}
            alt="banner image 4"
          />
        </Carousel>
      </CarouselContainer>

      <LogoContainer width={introWidth}>
        <LogoTitleImg fluid={data.logoTitle.childImageSharp.fluid} />
      </LogoContainer>

      <IntroContainer width={introWidth}>
        <SubHeadline>
          <b>
            DiTan school presents China's national martial art of Wu Shu and
            Mongolia's national contortion.
          </b>
        </SubHeadline>
        <SubHeadline>
          Whether you're looking to get into shape, learn self defense, improve
          in the performance art field or become an all-around athlete, we're
          ready to challenge you!
        </SubHeadline>
        <SubHeadline>We welcome adults and children of all ages.</SubHeadline>
      </IntroContainer>

      <Banners>
        <FirstHighlightedImg
          fluid={data.dividerImageOne.childImageSharp.fluid}
          alt="First cartoon"
        />
      </Banners>

      <MiddleSectionContainer>
        <SectionTitle>Glimpse of what you can achieve...</SectionTitle>
        <Paragraph>
          Wu Shu can help you improve your <Bold>balance</Bold>,{" "}
          <Bold>coordination</Bold>, <Bold>strength</Bold> and{" "}
          <Bold>endurance</Bold>.
        </Paragraph>

        <ResponsiveYoutubeVideoContainer>
          <ResponsiveYoutubeVideo
            title="scale-video"
            width="560"
            height="315"
            src="https://www.youtube.com/embed/wqJLpqfSDHc"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </ResponsiveYoutubeVideoContainer>
      </MiddleSectionContainer>

      <Banners>
        <SecondHighlightedImg
          fluid={data.dividerImageTwo.childImageSharp.fluid}
          alt="Second cartoon"
        />
      </Banners>

      <Container>
        <SectionTitle>Testimonials</SectionTitle>
        <div>
          <Testimonial
            fluid={data.oleksiiNosach.childImageSharp.fluid}
            content={`
			The first time I met Master Edward Aguirre happened in September 2004 in Moscow, Russia at the European Wushu Championships. At that time, I could not take part in the tournament, since the old Ukraine Federation did not include me in the national team selection, despite my full right to defend the honor of my country, because of the outbreak of conflict and the birth of the Ukrainian Wushu Federation. But I went to these competitions with Coach Oleg Valeryvich Chukanov to see the best European wushu athletes live, and also to support my teammate Andrey Koval. We sat in the stands, supported Andrey, and a young man came up to us and spoke in English. He introduced himself as Edward Aguirre from New York City USA, who was invited as a VIP guest by the Russian Federation. He gave a high assessment of the level of the Ukrainian athletes. It was nice to talk to an intelligent, cultured, well-mannered person who understands wushu and elite sports. He offered us cooperation. This is how the first acquaintance with Master Edward took place. Our second reunion was at the beginning of 2005, from Master Edward we received an invitation to his International Wushu Tournament, which was held in conjunction with a breakdance competition in Miami Florida, USA. Despite all the difficulties of obtaining a visa, financial, expenses, we managed to take part in this tournament. This competition left only positive, fond memories. 

			In the same year, the Ukrainian Wushu Federation began an independent activity, which caused a big conflict with the old federation. In autumn Master Edward came to Ukraine. He supported our independence very professionally, held a number of meetings with national sports officials, and also conducted a master class training for all the best students of the Brovary Wushu School. After this visit, our friendship was strengthened. The third meeting took place in 2006 in St. Petersburg, Russia at an International tournament. He also brought with him two athletes from Puerto Rico. Fourth meeting was in the summer of 2006, representatives of the Ukrainian Wushu Federation took part in a seminar for trainers of the International Wushu Federation. And after this seminar, we had plans to stay for another 3 weeks and train with the best Wushu athletes in Shichahai Sports School in Beijing . When we went to negotiate on training, they only laughed at us in Shichahai, and especially when we said that we wanted to train with the elite Beijing Wushu team. All requests were refused. There were only a few days left until the end of the workshop for trainers. And after this period, we could be on the street or go to find another wushu school, but there were no good athletes, coaches, gyms, equipment, and the second option frightened us more. 
			
			In the end, I offered to ask Master Edward and we wrote him an email. My knowledge of the language was poor, but one girl from our team still studied it and could write in English. We explained to Master Edward our plight and our situation. As a result, Master Edward immediately phoned his friend with China Wushu Association in Beijing and we were accepted to Shichahai and got training with the elite first squad. The fifth meeting took place in early 2007. Master Edward wanted to organize an international performance team. But, unfortunately, when we arrived, many athletes did not manage to come. And as a result, Master Edward found a USA competition for us and upon arrival in New York we trained and stayed at his home. Master Edward found us a wushu training hall, as well as a strength training hall, which allowed us to prepare for the competition. He also showed us New York and its sights. For 2 weeks of the competition, he covered all expenses and arranged for us training at the Li Jinheng Wushu school in Phoenix Arizona, USA. Master Li Jinheng had a soft-surfaced wushu training hall. After 10 days Master Edward flew in and we went to Los Angeles California for the NASKA Compete Nationals Open Tournament. For us, such competitions were new, interesting and we gained experience. In total, we spent 1.5 months in the United States and saw quite a few interesting things. 
			
			The sixth meeting took place in the fall of the same year 2007 in Beijing at the 9th World Wushu Championship. At this World Championship, I was supposed to play as part of the Ukrainian national team, since at the qualifying competitions I took 2nd place in the all-around, but unfortunately I did not get the opportunity to compete, because when all the tickets were already bought and preparations were underway, the old federation removed me from the list, for political reasons, as well as the war between the old and new federations. As a result, I went to these competitions, but as a spectator and press. During the days of the Championship, I, Chukanov Oleg Valeryvich and Master Edward talked a lot. We had a great time in a friendly atmosphere and watched the competition. After a long period of time, I can say with great confidence that Master Edward influenced my life in a big way. So if it were not for him, it is not known whether we would have been able to get to Shichahai, or see the United States. My first steps of learning English began with communicating with him.
			`}
            from="- Oleksii Nosach"
			url={{
				title: "Oleksii Nosach",
				url: "https://www.facebook.com/oleksii.nosach",
			  }}
          />
          <Testimonial
            fluid={data.lacyRoseElian.childImageSharp.fluid}
            content={`
						Coach Urnaa is amazing! She is extremely talented as a contortionist and is able to share her skills with her students. She helps correct her students’ technique and is very encouraging throughout the lesson. I never thought I’d be able to do contortion pushups, but Coach Urnaa was able to inspire me and help me through them. 
						She has a very calm and sweet demeanor, even when stretching you in uncomfortable positions! She never pushes too far, but always expects 100% from her students. I highly recommend Coach Urnaa to anyone interested in learning proper contortion skills and techniques. She is the best!
						`}
            from="- Lacy Rose Elian"
            url={{
              title: "Lacy Rose Elian",
              url: "http://www.lacyelian.com/",
            }}
          />
		  <Testimonial
		   	fluid={data.zhangChongwei.childImageSharp.fluid}
		  	content={`
			  My name is Chongwei Zhang. I am a Martial Arts Instructor and Stunt Actor, now based in Sydney, Australia. Edward Aguirre and I first met in May 1992. I was traveling with Shanghai Wushu (Chinese martial arts) Team who were supporting Ringling Bros. and Barnum & Bailey Circus on their tour round the United States. We had just arrived in New York. When Edward learnt of our arrival, he wasted no time going to Madison Square Garden where we were performing. He made his way to our green room and made fast friends with everyone on the team, including myself. During our performance Edward brought his team of Wushu students to see our shows. Both teams learnt a lot from each other and as we were a young professional martial arts team, this was particularly memorable for us — we learnt a lot about the United States and their views of Chinese Wushu. I was also very inspired by Edward’s efforts in promoting the learning of Wushu. 

When I returned to China after my performances, we maintained contact through many letters. I had never returned to the United States since, but Edward had since visited Shanghai countless times (he has many friends here who are fellow practitioners of Wushu). I have met with him on all his visits and have invited him to Shanghai Sports Institute where our Wushu Team based for interviews, he has also been a regular houseguest. He is a dear friend to me and my family. I once travelled from Shanghai to Beijing when I knew that his student was attending a Wushu competition in Beijing. I thought it was important to cheer them on.

In my opinion, Edward is a respectable Wushu teacher and master with a great love for promoting this art. He has learnt the arts from a young age and embodies the virtues we celebrate in Chinese martial arts. Edward is a worthy friend and a fellow comrade in our goal of spreading Chinese martial arts to the international community.

			  `}
			  from={
				  <>
						- Chongwei Zhang
						<div>
							<div>6th Dan Wushu</div>
							<div>6th Dan Taekwondo</div>
							<div>Former Member of Shanghai Wushu Team</div>
							<div>All-round Wushu Champion of China’s 8th National Game 1997</div>
						</div>
				  </>
			  }
			  url={{
				  title: "TK Martial Arts Academy Australia",
				  url: "http://tkmaa.com.au/"
			  }}
		  />
          <Testimonial
            fluid={data.ariadnaIgnatenko.childImageSharp.fluid}
            content={`
						"Coach Amarsanaa was my first contortion teacher who introduced me to this strong and extreme art. When I started practicing as an adult, coming from a yoga background, I needed a knowledgeable guidance of somebody who is experienced and could show me the safest and most effective way. Coach Amarsanaa not only creates a proper sequencing in her classes, she also knows the best adjustments for her students to assist in the safest and the most efficient way.
						She has an amazingly bright and warm personality with a calming voice. I would definitely recommend her classes to everyone who would like to embark on their contortion journey!"
						`}
            from="- Ariadna Ignatenko"
            url={{
              title: "Ariadna Ignatenko",
              url: "https://ariadnaignatenko.com",
            }}
          />
        </div>
        <ViewMoreLinkContainer>
          <ViewMoreLink to="/testimonials">View more...</ViewMoreLink>
        </ViewMoreLinkContainer>
      </Container>
    </Layout>
  );
};

const CarouselContainer = styled(Container)`
  height: 800px;
  margin-bottom: 10px;
  @media (max-width: 1220px) {
    height: 800px;
  }
  @media (max-width: 1020px) {
    height: 700px;
  }
  @media (max-width: 920px) {
    height: 600px;
  }
  @media (max-width: 820px) {
    height: 500px;
  }
  @media (max-width: 720px) {
    height: 400px;
  }
  @media (max-width: 620px) {
    height: 300px;
  } ;
`;

const ResponsiveYoutubeVideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;
`;
const ResponsiveYoutubeVideo = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 50px;
`;

const Paragraph = styled.p`
  margin-bottom: 40px;
`;

const LogoContainer = styled(Container)`
  padding-top: 0px;
  padding-bottom: 40px;
  @media (max-width: 720px) {
    padding: 0 15px;
  } ;
`;

const IntroContainer = styled(Container)`
  text-align: center;
  padding-top: 0px;
  padding-bottom: 40px;
  @media (max-width: 720px) {
    padding: 0 15px;
  } ;
`;
const LogoTitleImg = styled(Img)`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
`;
const Bold = styled.span`
  font-weight: 550;
  color: #444;
`;
const SubHeadline = styled.p`
  font-size: 1.8em;
  color: #333;
  line-height: 34px;
  @media (max-width: 920px) {
    font-size: 1.6em;
    line-height: 26px;
    margin-right: 15px;
    margin-left: 15px;
  }
  :last-child {
    margin-bottom: 0;
  }
`;
const MiddleSectionContainer = styled(Container)`
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
`;
const SectionTitle = styled.h2`
  color: #555;
  text-align: center;
`;
const FirstHighlightedImg = styled(Img)`
  max-width: 322px;
  width: 100%;
  height: 353px;
  display: block;
  margin: 0 auto;
  maxheight: 350px;
`;
const SecondHighlightedImg = styled(Img)`
  max-width: 400px;
  width: 100%;
  height: 353px;
  display: block;
  margin: 0 auto;
  maxheight: 350px;
`;
const ViewMoreLinkContainer = styled.div`
  text-align: right;
  font-size: 1.6em;
  padding-top: 35px;
`;
const ViewMoreLink = styled(Link)`
  text-decoration: none;
  color: rgb(219, 51, 62);
  padding-right: 15px;
`;

function Banners({ children }) {
  return (
    <BannersContainer>
      <Banner degree="3" />
      <Banner degree="-3" />
      {children}
    </BannersContainer>
  );
}
const BannersContainer = styled.div`
  position: relative;
  margin: 110px 0;
  @media only screen and (max-width: 768px) : {
    margin: 80px 0;
  }
`;
const Banner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ color }) => `${color || "rgba(255, 150, 50, 0.08)"}`};
  transform: ${(props) => `skew(0,${props.degree}deg) translateY(0)`};
`;

const query = graphql`
  query {
    banner0: file(relativePath: { eq: "index/banner_0.png" }) {
      childImageSharp {
        fluid(maxWidth: 1686) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    banner1: file(relativePath: { eq: "index/banner_1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2689) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    banner2: file(relativePath: { eq: "index/banner_2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1690) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    banner3: file(relativePath: { eq: "index/banner_3.png" }) {
      childImageSharp {
        fluid(maxWidth: 1690) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    banner4: file(relativePath: { eq: "index/banner_4.png" }) {
      childImageSharp {
        fluid(maxWidth: 1690) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    banner5: file(relativePath: { eq: "index/banner_5.png" }) {
      childImageSharp {
        fluid(maxWidth: 4388) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    },
    logoTitle: file(relativePath: { eq: "index/text.png" }) {
      childImageSharp {
        fluid(maxWidth: 828) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    title: file(relativePath: { eq: "index/title.png" }) {
      childImageSharp {
        fluid(maxWidth: 1105) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    dividerImageOne: file(relativePath: { eq: "index/cartoon_1.png" }) {
      childImageSharp {
        fluid(maxWidth: 1348) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    dividerImageTwo: file(relativePath: { eq: "index/cartoon_2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2048) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    lacyRoseElian: file(relativePath: { eq: "index/lacy_rose_elian.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1189) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ariadnaIgnatenko: file(
      relativePath: { eq: "index/ariadna_ignatenko.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1189) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    oleksiiNosach: file(relativePath: { eq: "index/oleksii_nosach.png" }) {
      childImageSharp {
        fluid(maxWidth: 788) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    zhangChongwei: file(relativePath: { eq: "index/zhang_chongwei.png" }) {
      childImageSharp {
        fluid(maxWidth: 1942) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
